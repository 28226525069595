import moment from 'moment';
import { statusOrder, statusLabelOrder } from '@/views/order/enum/StatusOrder';

export function mapperToOrderResponse(formData) {
  if (formData) {
    const order = formData;
    order.data.records = order.data.records.map(
      (item) => (
        { ...item, submittedAt: moment(item.submittedAt).format('DD/MM/YYYY HH:mm:ss'), synchronizedAt: moment(item.synchronizedAt).format('DD/MM/YYYY HH:mm:ss') }
        ),
    );
    return order;
  }
  return formData;
}

export function mapperToOrderHistoryResponse(formData) {
   if (formData) {
    const order = formData.map(
      (item) => ({ ...item,
        updatedAt: moment(item.updatedAt).format('DD/MM/YYYY HH:mm:ss'),
        status: statusOrder[item.newStatus],
        url: item.metadata.filter((el) => el.label === 'url'),
        metadata: item.metadata.map(
          (meta) => ({ ...meta,
            label: statusLabelOrder[meta.label] || meta.label,
          }),
          ),
      }),
    );
   return order;
   }
  return formData;
}

export default mapperToOrderResponse;
