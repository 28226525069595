import { get, put } from '@/service/api';
import remapSortDesc, { buildSortUrl, buildUrl } from '@/utils/service-utils';
import { mapperToOrderResponse, mapperToOrderHistoryResponse } from './order-mapper';

const currentUser = JSON.parse(localStorage.getItem('current_user'));

export default async function getInvoice(id, size, page) {
  const { data } = await get(`sellers/invoice/order/${id}/seller-commissioned-item?size=${size}&page=${page}`);
  return data;
}

export async function getInvoiceTotal(id) {
  const { data } = await get(`sellers/invoice/seller-commissioned-item/${id}/statistics`);
  return data;
}

export async function getOrder(page, itemsPerPage, orderBy, sortBy, query) {
  const params = await buildUrl(page, itemsPerPage);
  const mapedOrder = remapSortDesc(orderBy);
  const sortOptions = buildSortUrl(sortBy, mapedOrder);
  const filter = `${query.length > 0 ? `${query.replaceAll('initialDate', 'submittedAt').replaceAll('finalDate', 'submittedAt')}` : ''}`;
  const data = await get(`ui-integrator/orders/fetch?${params}&filter=${filter}&username=${currentUser.username}${sortOptions}`);
  return mapperToOrderResponse(data);
}

export async function getListOrder(formData = {}, paginationOptions) {
  const { page, itemsPerPage, sortDesc, sortBy } = paginationOptions;
  const params = await buildUrl(page, itemsPerPage);
  const mapedOrder = remapSortDesc(sortDesc);
  const sortOptions = buildSortUrl(sortBy, mapedOrder);

  const { number, initialDate, finalDate, customerName, status } = formData;

  const query = [
    number ? `( number = !${number} )` : '',
    initialDate ? `( submittedAt >= ${initialDate} )` : '',
    finalDate ? `( submittedAt <= ${finalDate}T23:59 )` : '',
    customerName ? `( customerName = !${customerName} )` : '',
    status ? `( status = ${status} )` : '',
  ]
    .filter((condition) => condition !== '')
    .join(' and ');

  const data = await get(`ui-integrator/orders/fetch?${params}&filter=${query}&username=${currentUser.username}${sortOptions}`);
  return mapperToOrderResponse(data);
}

export async function canCancelOrder(orderNumber) {
  const { data } = await get(`ui-integrator/orders/canCancelOrder/${orderNumber}`);
  return data;
}

export async function cancelOrder(orderNumber) {
  const data = await put(`ui-integrator/orders/${orderNumber}/cancel-suborder`);
  return data;
}

export async function getOrderByNumber(orderId) {
  const data = await get(`ui-integrator/orders/${orderId}/summary`);
  return data;
}

export async function getHistoryOrder(orderId) {
  const response = await get(`orders/${orderId}/history`);
  if (response.status === 200 && response.data) return mapperToOrderHistoryResponse(response.data);
  console.error('Erro ao buscar histórico: ', response?.response?.data?.error_description);
  return [];
}
