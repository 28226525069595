<template>
  <input class="label" v-model="local" v-bind="$attrs" />
</template>

<script>
import Cleave from 'cleave.js';

const MaskLabelOptions = {
  numeral: true,
  numeralDecimalScale: 2,
  numeralDecimalMark: ',',
  delimiter: '.',
  prefix: 'R$ ',
};

export default {
  props: {
    value: {
      type: null,
      required: true,
    },
    options: {
      type: Object,
      default: () => MaskLabelOptions,
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        if (newValue !== undefined && newValue !== null) {
          const stringValue = newValue.toString();
          const rawValue = stringValue.replace(this.pattern, '');

          const decimalValue = Number(rawValue).toFixed(2);

          if (this.cleave && this.cleave.setRawValue) {
            this.cleave.setRawValue(decimalValue);
          }

          this.local = decimalValue;
        }
      },
    },
  },
  data() {
    return {
      local: '',
      pattern: '',
      cleave: null,
    };
  },
  created() {
    this.pattern = this.regexPatternBuilder(this.$props?.options?.prefix);
  },
  mounted() {
    this.initializeCleave();
  },
  beforeDestroy() {
    this.destroyCleave();
  },
  methods: {
    initializeCleave() {
      this.cleave = new Cleave(this.$el, {
        ...this.options,
        onValueChanged: this.onValueChanged.bind(this),
      });

      this.local = this.value;
      this.cleave.setRawValue(this.value);
    },
    regexPatternBuilder(symbols) {
      if (!symbols) return '';
      const pattern = new RegExp(
        symbols
          .split('')
          .map((char) => `\\${char}`)
          .join(''),
        'g',
      );

      return pattern;
    },
    destroyCleave() {
      if (this.cleave) {
        this.cleave.destroy();
        this.cleave = null;
      }
    },
    onValueChanged({ target }) {
      this.$nextTick(() => {
        this.local = target.value;
        this.$emit('input', target?.rawValue);
        this.$emit('input-formatted-value', target?.value);
      });
    },
  },
};
</script>

<style scoped lang="scss">
input.label {
  width: 100%;
  display: block;
  outline: none;
  border: none;
  background-color: transparent;
  caret-color: transparent;
}
</style>
