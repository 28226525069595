<template>
  <v-form ref="filterForm" lazy-validation @submit="onSubmitHandler">
    <content-base-layout>
      <template v-slot:content>
        <div class="form-content">
          <div class="col-3">
            <label class="filter-label">Nº do pedido</label>
            <v-text-field
              v-model="form.number"
              placeholder="Digite"
              dense
              filled
              prepend-inner-icon="mdi-magnify"
            />
          </div>
          <div class="col-3">
            <label class="filter-label">Data inicial</label>
            <mask-input
              v-model="form.initialDate"
              :options="DateMaskOptions"
              dense
              filled
              placeholder="Digite"
              @input-formatted-value="onMaskInputDateChange($event, 'initialDate')"
              :rules="[Rules.dateFullyFilled]"
            />
          </div>
          <div class="col-3">
            <label class="filter-label">Data final</label>
            <mask-input
              v-model="form.finalDate"
              :options="DateMaskOptions"
              dense
              filled
              placeholder="Digite"
              @input-formatted-value="onMaskInputDateChange($event, 'finalDate')"
              :rules="[Rules.dateFullyFilled]"
            />
          </div>
          <div class="col-3">
            <label class="filter-label">Nome do cliente</label>
            <v-text-field v-model="form.customerName" placeholder="Digite" dense filled prepend-inner-icon="mdi-magnify" />
          </div>

          <div class="col-3">
            <label class="filter-label">Situação do pedido</label>
            <v-autocomplete
              class="data_filter_select_input"
              :no-filter="true"
              dense
              item-text="name"
              item-value="id"
              v-model="form.status"
              :items="orderStatusOptions"
              placeholder="Todos"
            />
          </div>
        </div>
      </template>

      <template v-slot:actions>
        <filter-base-actions @onReset="onClearHandler" />
      </template>
    </content-base-layout>
  </v-form>
</template>

<script>
import moment from 'moment';
import ContentBaseLayout from '@/components/filter-layout/ContentBaseLayout';
import FilterBaseActions from '@/components/filter-layout/FilterBaseActions';
import MaskInput from '@/components/template/form/input/MaskInput';
import { allowedStatus } from '@/views/order/enum/StatusOrder';

const FilterFields = {
  number: '',
  initialDate: '',
  finalDate: '',
  customerName: '',
  status: '',
};

const DateMaskOptions = {
  date: true,
  datePattern: ['d', 'm', 'Y'],
  delimiter: '/',
  dateMin: '01/01/1900',
  dateMax: '12/31/2099',
  placeholder: 'DD/MM/YYYY',
};

export default {
  components: { MaskInput, ContentBaseLayout, FilterBaseActions },
  computed: {
    DateMaskOptions() {
      return DateMaskOptions;
    },
    Rules() {
      return {
        dateFullyFilled: (value) => {
          if (!value) return true;
          const numberValue = value.replace(/\D/g, '');

          if (numberValue.length < 8) {
            return 'Preencha a data corretamente. Ex: dd/mm/aaaa';
          }

          return true;
        },
      };
    },
  },
  data() {
    return {
      form: { ...FilterFields },
      formFormattedValues: {
        initialDate: '',
        finalDate: '',
      },
      orderStatusOptions: [],
    };
  },
  mounted() {
    this.getOrderStatusOptions();
  },
  methods: {
    getOrderStatusOptions() {
      this.orderStatusOptions = allowedStatus;
    },
    onSubmitHandler(e) {
      e.preventDefault();
      const { filterForm } = this.$refs;
      const validated = filterForm?.validate();

      if (!validated) return;

      const formData = { ...this.form, ...this.formFormattedValues };
      this.$emit('onSubmit', formData);
    },
    onClearHandler() {
      this.form = { ...this.form, ...FilterFields };
      this.formFormattedValues = {
        initialDate: '',
        finalDate: '',
      };
      this.$emit('onReset', this.form);
    },
    onMaskInputDateChange(value, fieldName) {
      try {
        if (!value) {
          this.formFormattedValues[fieldName] = '';
          return;
        }
        this.formFormattedValues[fieldName] = moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD');
      } catch (error) {
        console.error('Error on DataFilter component at onMaskInputDateChange method. ', error);
      }
    },
  },
};
</script>
