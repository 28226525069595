<template>
  <v-container class="crud-content" fill-height>
    <ListLayout :title="title" :info="info">
      <template v-slot:filter>
        <filter-container>
          <template v-slot:content>
            <order-form-filter @onSubmit="onSubmitFilterHandler" @onReset="onResetFilterHandler" />
          </template>
        </filter-container>
      </template>
      <template v-slot:content>
        <v-data-table
          :headers="headers"
          :loading="loading"
          :items="content"
          class="elevation-1 custom-table"
          :options.sync="paginationOptions"
          :server-items-length="totalElements"
          :footer-props="{
            'items-length': 100,
            'items-per-page-text': 'Linhas por página',
            'items-per-page-options': [10, 20, 50],
            pageText: '{0}-{1} de {2}',
          }"
        >
          <template v-slot:[`item.customer.firstName`]="{ item }">
            <div class="camelcase">{{ item.customer.firstName }}</div>
          </template>
          <template v-slot:[`item.itemsAmount`]="{ item }">
            <div class="d-flex justify-center">
              <v-chip class="itens">{{ item.itemsAmount }}</v-chip>
            </div>
          </template>
          <template v-slot:[`item.shippingPackages`]="{ item }">
            <div class="d-flex justify-center">
              <v-chip class="itens">{{ item.shippingPackages.length }}</v-chip>
            </div>
          </template>
          <template v-slot:[`item.total`]="{ item }">
            <NumberMaskLabel class="color--grey-1" v-model="item.total" filled />
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <div class="camelcase" :class="getColorStatus(statusOrder[item.status])">{{ statusOrder[item.status] }}</div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex justify-center">
              <v-icon color="primary" @click="openDetail(item.number)">mdi-magnify-plus-outline</v-icon>
            </div>
          </template>
          <template v-slot:no-data>
            <div class="pt-10 pb-10">
              Nenhum resultado encontrado
            </div>
          </template>
        </v-data-table>
      </template>
    </ListLayout>
  </v-container>
</template>

<script>
import ListLayout from '@/components/template/list/ListLayout';
import notification from '@/service/notification';
import dataCrudMixin from '@/components/template/crud/mixins/dataCrudMixin';
import { statusOrder } from './enum/StatusOrder';
import { getListOrder } from '@/service/order/order-service';
import NumberMaskLabel from '@/components/template/form/NumberMaskLabel';
import { routerPush } from '@/service/kplaceRouter';
import OrderFormFilter from './filter/OrderFormFilter';
import FilterContainer from '@/components/filter-layout/FilterContainer';

export default {
  components: {
    ListLayout,
    NumberMaskLabel,
    OrderFormFilter,
    FilterContainer,
  },
  mixins: [dataCrudMixin],
  data() {
    return {
      title: 'Pedidos',
      info: '',
      search: '',
      loading: false,
      statusOrder,
      formDataFilter: {},
      paginationOptions: {},
      headers: [
        { text: 'Nº do pedido', align: 'start', sortable: true, value: 'number' },
        { text: 'Data', sortable: true, value: 'submittedAt' },
        { text: 'Nome do cliente', sortable: true, value: 'customer.fullName' },
        { text: 'Itens', sortable: false, value: 'itemsAmount' },
        { text: 'Pacotes', sortable: false, value: 'shippingPackages', formatter: this.countTotal },
        { text: 'Total', sortable: false, value: 'total' },
        { text: 'Status', sortable: true, value: 'status' },
        { text: 'Ações', sortable: false, value: 'actions' },
      ],
    };
  },
  watch: {
    paginationOptions: {
      handler() {
        this.init();
      },
    },
  },
  methods: {
    init() {
      this.getOrderListHandler(this.formDataFilter);
    },
    async getOrderListHandler(formDataFilter) {
      try {
        this.loading = true;
        const requestReponse = await getListOrder(formDataFilter, this.paginationOptions);

        if (requestReponse.status >= 200 && requestReponse.status <= 300) {
          this.content = requestReponse.data.records;
          this.totalElements = requestReponse.data.totalRecords;
        } else {
          notification(`${requestReponse.response.data.message}`, 'error');
        }
      } catch (e) {
        console.error('Error on getOrderListHandler', e);
        notification(`Erro ao realizar busca de pedidos : ${e}`, 'error');
      } finally {
        this.loading = false;
      }
    },
    onSubmitFilterHandler(formDataFilter) {
      this.formDataFilter = formDataFilter;
      this.getOrderListHandler(this.formDataFilter);
    },
    onResetFilterHandler(formDataFilter) {
      this.formDataFilter = formDataFilter;
      this.getOrderListHandler(this.formDataFilter);
      this.paginationOptions.page = 1;
    },
    openDetail(number) {
      routerPush(`/order/detail/${number}`);
    },
    getColorStatus(status) {
      switch (status) {
        case statusOrder.finalizado:
          return 'color--green-0';
        case statusOrder.cancelado:
          return 'color--red-0';
        case statusOrder.aguardando_pagamento:
          return 'color--blue-0';
        default:
          return '';
      }
    },
  },
  async mounted() {
    if (this.paginationOptions.sortBy.length === 0) {
      this.paginationOptions.sortBy.push('submittedAt');
      this.paginationOptions.sortDesc.push(false);
    }
    await this.init();
  },
};
</script>

<style lang="scss" scoped>
.crud-content {
  background-color: #f8f8f8;
  height: calc(100% - 160px);
  width: 100%;
  margin-top: 126px;
  border-radius: 15px;
  padding: 20px 20px 20px 20px;
}
.itens.theme--light.v-chip {
  color: white;
  background: #f44fe3;
  font-size: 10px;
  width: 20px;
  height: 20px;
  padding: 7px;
  font-family: $font-family-medium;
}
</style>
